import React, { useEffect, useContext, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { motion, useAnimation } from "framer-motion"

import Context from "../../context/"
import ContentWrapper from "../../styles/contentWrapper"
import Underlining from "../../styles/underlining"
import Social from "../social"
import { lightTheme, darkTheme } from "../../styles/theme"
import Message1 from "../../../static/message1.png"
import Message2 from "../../../static/message2.png"
import Message3 from "../../../static/message3.png"
import Message4 from "../../../static/message4.png"
import { detectMobileAndTablet, isSSR } from "../../utils"

const Container = styled.section`
  padding-left: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-left: 0;
    margin-left: -3rem;
  }
`
const ReverseContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`

const FlexSection = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
  }
`

const StyledSection = styled.section`
  height: auto;
  background: ${({ theme }) => theme.colors.background};
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // margin-bottom: 6rem;
    // @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    //   margin-bottom: 4rem;
    // }
    .greetings {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .emoji {
      margin-left: 0.75rem;
      width: 2.2rem;
      height: 2.2rem;
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        margin-left: 1rem;
        width: 3rem;
        height: 3rem;
      }
    }
    .title {
      margin-bottom: 1.5rem;
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        margin-bottom: 0;
      }
    }
    .subtitle {
      margin-top: -0.75rem;
    }
    .description {
      font-size: 1.125rem;
      margin-bottom: 2rem;
    }
  }
`

const AnimatedUnderlining = motion.custom(Underlining)

const Hero = ({ content }) => {
  const { frontmatter, body } = content[0].node
  const [windowWidth, setWindowWidth] = useState(0)
  const { isIntroDone, darkMode } = useContext(Context).state

  // Controls to orchestrate animations of greetings, emoji, social profiles, underlining
  const gControls = useAnimation()
  const eControls = useAnimation()
  const sControls = useAnimation()
  const uControls = useAnimation()
  const m1Controls = useAnimation()
  const m2Controls = useAnimation()
  const m3Controls = useAnimation()
  const m4Controls = useAnimation()

  // Start Animations after the splashScreen sequence is done
  useEffect(() => {
    const pageLoadSequence = async () => {
      if (isIntroDone) {
        eControls.start({
          rotate: [0, -10, 12, -10, 9, 0, 0, 0, 0, 0, 0],
          transition: { duration: 2.5, loop: 3, repeatDelay: 1 },
        })
        await gControls.start({
          opacity: 1,
          y: 0,
          transition: { delay: 0.2 },
        })
        await m1Controls.start({
          opacity: 1,
          y: 0,
          transition: { delay: 0.22 },
        })
        await m2Controls.start({
          opacity: 1,
          y: 0,
          transition: { delay: 0.24 },
        })
        await m3Controls.start({
          opacity: 1,
          y: 0,
          transition: { delay: 0.26 },
        })
        await m4Controls.start({
          opacity: 1,
          y: 0,
          transition: { delay: 0.28 },
        })
        await sControls.start({
          opacity: 1,
          x: 0,
        })
        // Animate underlining to hover state
        await uControls.start({
          boxShadow: `inset 0 -2rem 0 ${
            darkMode ? darkTheme.colors.secondary : lightTheme.colors.secondary
          }`,
          transition: { delay: 0.4, ease: "circOut" },
        })
      }
    }
    pageLoadSequence()
  }, [isIntroDone, darkMode, eControls, gControls, sControls, uControls])

  useEffect(() => {
    let handleWindowSizeChange
    // if (isSSR) is necessary to prevent error during the gatsby build
    if (!isSSR) {
      handleWindowSizeChange = () => setWindowWidth(window.innerWidth)
      // set initial innerWidth when component mounts
      setWindowWidth(window.innerWidth)
    }
    // Add event listener to update windowWidth in state
    window.addEventListener("resize", handleWindowSizeChange)
    return () => window.removeEventListener("resize", handleWindowSizeChange)
  }, [windowWidth])

  return (
    <FlexSection id="hero">
      <StyledSection id="hero">
        <StyledContentWrapper>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={gControls}
            data-testid="animated-heading"
          >
            <h1 className="title">
              <div className="greetings">
                {frontmatter.greetings}
                <motion.div
                  animate={eControls}
                  style={{ originX: 0.7, originY: 0.7 }}
                >
                  <Img
                    className="emoji"
                    fluid={frontmatter.icon.childImageSharp.fluid}
                  />
                </motion.div>
              </div>
              {frontmatter.title}
            </h1>
            <h2 className="subtitle">
              {frontmatter.subtitlePrefix}{" "}
              <AnimatedUnderlining animate={uControls} big>
                {frontmatter.subtitle}
              </AnimatedUnderlining>
            </h2>
            <div className="description">
              <MDXRenderer>{body}</MDXRenderer>
            </div>
          </motion.div>
          <motion.div initial={{ opacity: 0, x: 20 }} animate={sControls}>
            <Social fontSize=".95rem" padding=".3rem 1.25rem" width="auto" />
          </motion.div>
        </StyledContentWrapper>
      </StyledSection>
      <Container>
        <ReverseContainer>
          <motion.img
            width={!detectMobileAndTablet(windowWidth + 700) ? 240 : 210}
            src={Message1}
            alt="message1"
            initial={{ opacity: 0, x: 20 }}
            animate={m1Controls}
          />
        </ReverseContainer>
        <motion.img
          width={!detectMobileAndTablet(windowWidth + 700) ? 330 : 300}
          src={Message2}
          alt="message2"
          initial={{ opacity: 0, x: 20 }}
          animate={m2Controls}
        />
        <ReverseContainer>
          <motion.img
            width={!detectMobileAndTablet(windowWidth + 700) ? 150 : 120}
            src={Message3}
            alt="message3"
            initial={{ opacity: 0, x: 20 }}
            animate={m3Controls}
          />
        </ReverseContainer>
        <motion.img
          width={!detectMobileAndTablet(windowWidth + 700) ? 330 : 300}
          src={Message4}
          alt="message4"
          initial={{ opacity: 0, x: 20 }}
          animate={m4Controls}
        />
      </Container>
    </FlexSection>
  )
}

Hero.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        body: PropTypes.string.isRequired,
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default Hero
